import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";

const LogoWrapper = styled.div`
  color: var(--color-white);
  font-size: 12px;
  padding: 10px;
  height: 100%;
  font-weight: 700;
  display: flex;
  align-items: center;
  @media ${(props) => props.theme.mediaQueries.smallest} {
    padding-right: 6px;
    padding-left: 10px;
  }
`;

const LinkTo = styled(Link)`
  height: 40px;
  @media ${(props) => props.theme.mediaQueries.smallest} {
    height: 30px;
  }
`;

const Logo = () => {
  const handleScrollToTop = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <LogoWrapper>
      <LinkTo className="no-phone">
        <img
          src=""
          alt="Analize Sportive"
          width="40px"
          height="40px"
        />
      </LinkTo>
      <LinkTo
        className="no-mac"
        onClick={handleScrollToTop}
        smooth={"true"}
        duration={500}
        // spy={true}
        exact="true"
        offset={-80}
      >
        <img
          src=""
          alt="Analize Sportive"
          width="30px"
          height="30px"
        />
      </LinkTo>
    </LogoWrapper>
  );
};

export default Logo;
