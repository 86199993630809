import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import NavItem from "./Navitem/Navitem";

// FONTS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList, faComments, faDice, faDollarSign, faFile, faFutbol, faInfoCircle, faListUl, faPaste, faPercentage, faShoePrints, faTasks, faUser, faUsers, faVirus, faViruses } from "@fortawesome/free-solid-svg-icons";


const Nav = styled.nav`
  display: flex;
  margin-top: ${(props) => (props.mobile ? "-60px" : null)};
  width: 100%; 
`;
const Ul = styled.ul`
  display: flex;
  flex-direction: ${(props) => (props.mobile ? "column" : "row")};
  align-items: center;
  height: 100%;
  @media ${(props) => props.theme.mediaQueries.smallest} {
    display: none;
  }
`;
const SeparatorDash = styled.div`
  border-bottom: 1px solid var(--color-borderR);
  margin: 12px 0px !important;
`;
const NavItems = ({ isActive, mobile, clicked, toggleTheme, title }) => {
  const { pathname } = useLocation();
  const newPath = pathname.split("/");
  const handleScrollToTop = () => {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }
  return (
    <Nav>
      {mobile ? (
        <div className="d-flex flex-column w-100 position-relative mt-2">
          <div className="d-flex justify-content-around">
            <div className="w-100 text-center">
              Home
            </div>
            {/* <div className="w-100 text-center">
              About
            </div>
            <div className="w-100 text-center">
              Allocation
            </div>
            <div className="w-100 text-center">
              Roadmap
            </div> */}
          </div>
        </div>
      ) : (
        <Ul mobile={mobile} >
          {/* <NavItem to="/">Home</NavItem> */}
          {/* <NavItem to="/vaults">Staking Pool</NavItem> */}
          {/* <NavItem to="/pools">Pools</NavItem> */}
          {/* <NavItem> <a href="https://bitnautic.com/" target="_blank">Home</a></NavItem> */}
          {/* <NavItem to="/vaults">Info</NavItem> */}
          {/* <NavItem to="/about">About</NavItem>
        <NavItem to="/tokenomics">Tokenomics</NavItem>
        <NavItem to="/how-to-buy" newState="true">How to Buy</NavItem>
        <NavItem link="/team" to="/team">Team</NavItem> */}
          {/* <NavItem to="/nft">NFTs</NavItem>
        <NavItem to="/merch">Merch</NavItem> */}
        </Ul>
      )}
    </Nav>
  );
};

export default NavItems;
