import React, {useEffect, useCallback, useMemo, useState } from 'react';
import { useContracts } from '../../store/contracts';
import BigNumber from 'bignumber.js';
import { BIG_TEN, BIG_ZERO, BIG_ONE } from '../../utils/bigNumber'
import constants from "../../config/constants";
import erc20abi from "erc-20-abi";
import { useWallet } from "../../store/wallet";


function APR({ index, taddress, quoteTokenAddress }) {
    const { web3 } = useWallet();
    const BSC_BLOCK_TIME = 3
    const BTNT_PER_BLOCK = 0.05
    const BLOCKS_PER_YEAR = (60 / BSC_BLOCK_TIME) * 60 * 24 * 365 // 10512000
    const BTNT_PER_YEAR = BTNT_PER_BLOCK * BLOCKS_PER_YEAR
    const { bnbFarm, pancakeRouter, bnbLpToken, btntToken, multiCall } = useContracts();
    let quoteTokenContract = {}
    let lpContract = {}
    const [aprValue, setAprValue] = useState(0)
    
    const getFarmQuoteTokenPrice = async (index, quoteTokenAmountTotal) => {
        if (index === 0) {
            const [, _bnbPrice] = await pancakeRouter.methods
                .getAmountsOut(new BigNumber(1e0).toString(), [
                    constants.ethAddress,
                    constants.usdtAddress,
                ])
                .call();
        return new BigNumber(_bnbPrice);
        } else {
            return new BigNumber(quoteTokenAmountTotal);
        }
        // return quoteTokenAmountTotal;
        
      }

      const getFarmApr = (poolWeight, usdtPrice, totalLiquidity, taddress) => {
        const yearlyBTNTRewardAllocation = poolWeight ? poolWeight.times(BTNT_PER_YEAR) : new BigNumber(NaN)
        const BTNTRewardsApr = yearlyBTNTRewardAllocation.times(usdtPrice).div(totalLiquidity).times(100)
        let BTNTRewardsAprAsNumber = null
        if (!BTNTRewardsApr.isNaN() && BTNTRewardsApr.isFinite()) {
            BTNTRewardsAprAsNumber = BTNTRewardsApr.toNumber()
          }
        const lpRewardsApr = 2000 ?? 0
        return { BTNTRewardsApr: BTNTRewardsAprAsNumber, lpRewardsApr }
      }

      const displayAPY = useMemo(() => {
        return new BigNumber(aprValue).multipliedBy(1e-18).toFixed(12);
    }, [aprValue]);

    const fetchData = useCallback(async () => {
        if (bnbFarm?.methods && taddress && quoteTokenAddress) {
        //Formula for Pool Weight
        const usdtPrice = await pancakeRouter.methods
                .getAmountsOut(new BigNumber(1e18).toString(), [
                    constants.tokenAddress,
                    constants.usdtAddress,
                ])
                .call();
        // const usdtPrice = 413562372740312383
        const totalAllocPoint = await bnbFarm.methods.totalAllocPoint().call();
        const allocPointData = await bnbFarm.methods.poolInfo(index).call();
        const allocPoint = allocPointData ? new BigNumber(allocPointData.allocPoint): BIG_ZERO;
        const poolWeight = allocPoint.div(new BigNumber(totalAllocPoint))
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
        quoteTokenContract = new web3.eth.Contract(erc20abi, quoteTokenAddress)
        lpContract = new web3.eth.Contract(erc20abi, taddress)
        //Formula for Total Liquidity
        const results = await Promise.all([
            btntToken.methods.balanceOf(taddress).call(),
            quoteTokenContract.methods.balanceOf(taddress).call(),
            lpContract.methods.balanceOf(constants.farmAddress).call(),
            lpContract.methods.totalSupply().call(),
            btntToken.methods.decimals().call(),
            quoteTokenContract.methods.decimals().call(),
        ]);

        const [tokenBalanceLP, quoteTokenBalanceLP, lpTokenBalanceBF, lpTotalSupply, tokenDecimals, quoteTokenDecimals] = results;

        const lpTokenRatio = new BigNumber(lpTokenBalanceBF).div(new BigNumber(lpTotalSupply))
        // Raw amount of token in the LP, including those not staked
        const tokenAmountTotal = new BigNumber(tokenBalanceLP).div(BIG_TEN.pow(tokenDecimals))
        const quoteTokenAmountTotal = new BigNumber(quoteTokenBalanceLP).div(BIG_TEN.pow(quoteTokenDecimals))
        const quoteTokenAmountBF = quoteTokenAmountTotal.times(lpTokenRatio)
        const lpTotalInQuoteToken = quoteTokenAmountBF.times(new BigNumber(2))
        const tokenPriceVsQuote =  quoteTokenAmountTotal.div(tokenAmountTotal).toJSON();
        const bnbPriceBusd = tokenPriceVsQuote ? BIG_ONE.div(tokenPriceVsQuote) : BIG_ZERO
        const quoteTokenPriceBusd = await getFarmQuoteTokenPrice(index, bnbPriceBusd)
        const totalLiquidity = new BigNumber(lpTotalInQuoteToken).times(quoteTokenPriceBusd)
        const aprCalc = getFarmApr(poolWeight, usdtPrice[1], totalLiquidity, taddress)
        const apr = aprCalc.BTNTRewardsApr;
        setAprValue(apr);
        
    }
}, [bnbFarm, taddress, index, btntToken]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <>
        {displayAPY}
        </>
    )
}
    

export default APR;