import React from "react";
import { Link } from "react-scroll";
import "./footer.css";
// FONTS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDiscord,
  faFacebook,
  faInstagram,
  faGithub,
  faMedium,
  faReddit,
  faTelegram,
  faTiktok,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
const Footer = () => {
  return (
    <div class="footer" id="footer">
      <div class="container">
        <>         
          <div class="row align-items-center">
            <div class="col-12 col-lg-6">
              <h3 class="mb-0 text-black text-center">
              © 2021 BitNautic. All rights reserved to BitNautic.
              </h3>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default Footer;
