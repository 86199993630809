const dark = {
  title: "dark",
  colors: {
    orange: "#fec33c",
    main: "#fff",
    mainDark: "#fff",
    mainLight: "#000",
    mainLighter: "#000",
    blackAndWhite: "#000",
    blackAndWhiteR: "#fff",
    dark75: "#fff",
    textButton: "#fff",
    textSecondaryButton: "#fff",
    textColor: "#fff",
    whiteColor: "#fff",
    shadow: "rgba(0,0,0,.2)",
    border: "#191919",
    border2: "#101010",
    border3: "#2d3646",
    border4: "rgba(173, 191, 206, 0.2)",
    borderR: "#f2f2f2",
    picks: "#ffffff90",
    hover: "#262a38",
    hover2: "#232323",
    hover3: "#313131",
    hoverText: "#fff",
    score: "#fff",
    bell: "#fec33c",
    cardHeader: "#0e1726",
    background1: "#232323",
    background2: "#181818",
    background3: "#414141",
    background4: "#414141",
    background5: "#151515",
    background6: "#2d2d2d",
    background7: "#414141",
    background8: "#2d2d2d",
    background9: "#393939",
    background10: "#2d2d2d",
    background11: "#191919",
    background12: "#fff",
    background13: "#181818",
    background14: "#9fa2a5",
    background15: "#fbc33b",
    background16: "#282828",
    background17: "#fec33c",
    bg1: "#2d1925",
    bg2: "#c2b280",
    bg3: "#15181d",
    bg4: "#15161d"
  },
  backgrounds: {
    bgMain: "#272E48",
    bgHeader: "#1A233A",
    bgCardHome:
      'url("")',
    ttbg: 'url() 0 -332px no-repeat;'
  },
  buttons: {
    buttonMain: "rgb(42, 113, 174)",
  },

  mediaQueries: {
    smallest: `only screen and (max-width: 25em)`,
    small: `only screen and (max-width: 37.5em)`,
    medium: `only screen and (max-width: 50em)`,
    medium2: `only screen and (max-width: 60em)`,
    large: `only screen and (max-width: 70em)`,
    large2: `only screen and (max-width: 80em)`,
    largest: `only screen and (max-width: 90em)`,
  },
};

export default dark;
