/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from "react";
import Navbar from "../../components/Navigation/Navbar/Navbarnou";
import { useDencrypt } from "use-dencrypt-effect";
import {
  SweetAlertNoButtons,
  SweetAlertTest,
} from "../../utils/SweetAlert/SweetAlert";
import Tooltip from "@material-ui/core/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import Button from "@material-ui/core/Button";
import Web3 from "web3";
import "./vaults.css";
import Farm from "../../components/Farm/Farm";
import { useContracts } from "../../store/contracts";
import BigNumber from "bignumber.js";
import { useMemo } from "react";
import constants from "../../config/constants";
import { makeStyles } from "@material-ui/core/styles";
import erc20abi from "erc-20-abi";
import { useWallet } from "../../store/wallet";
import APR from "../Vaults/apr";

// images
import FarmLogo from "../../assets/bitnautic_logo_blue_small.png";
import CoreIcon from "../../assets/core_icon.svg";
import Dialog from "../../../src/components/Dialog/Dialog";
import Farm2 from "../../components/Farm/Farm2";
import { async } from "rxjs";
const tokenSymbol = "btntCoin";
const tokenDecimals = 18;
const tokenAddress = "0x867B96B33B2c13CC8cB78A9aA95420c6cD42C4c6";
// const tokenImage = { FarmLogo };

const values = ["Select a material"];

const Card = ({
  index,
  name,
  farm,
  taddress,
  priceTag,
  image,
  quoteTokenAddress,
}) => {
  const { web3, isWeb3Enabled } = useWallet();
  const { result, dencrypt } = useDencrypt();
  const { address } = useWallet();
  const { bnbFarm, pancakeRouter, bnbLpToken } = useContracts();
  const [bnbFarmStaked, setBnbFarmStaked] = useState(new BigNumber(0));
  const [farmModal, setFarmModal] = useState(false);
  const [farmModal2, setFarmModal2] = useState(false);
  const [bnbPrice, setBnbPrice] = useState(new BigNumber(0));
  const [usdtPrice, setUsdtPrice] = useState(new BigNumber(0));
  const [lpBalance, setLpBalance] = useState(new BigNumber(0));
  const [pending, setPending] = useState(new BigNumber(0));
  const [depositedAmount, setDepositedAmount] = useState(new BigNumber(0));
  const [claiming, setClaiming] = useState(false);
  const [depositing, setDepositing] = useState(false);
  const [loading, setLoading] = useState(false);
  let lpContract = {};
  const displayBnbFarmStaked = useMemo(() => {
    return bnbFarmStaked.multipliedBy(1e-18).toFixed(4);
  }, [bnbFarmStaked]);

  const displayAPY = useMemo(() => {
    return 0;
  }, [bnbFarmStaked]);

  const displayBnbPrice = useMemo(() => {
    return bnbPrice.multipliedBy(1e-18).toFixed(12);
  }, [bnbPrice]);

  const displayUSDTPrice = useMemo(() => {
    return usdtPrice.multipliedBy(1e-18).toFixed(12);
  }, [usdtPrice]);

  // poolInfo

  const fetchData = useCallback(async () => {
    if (address && bnbFarm?.methods && taddress) {
      const _staked = await bnbFarm.methods.deposited(index, address).call();
      setBnbFarmStaked(new BigNumber(_staked));
      const [, _bnbPrice] = await pancakeRouter.methods
        .getAmountsOut(new BigNumber(1e18).toString(), [
          constants.tokenAddress,
          constants.ethAddress,
        ])
        .call();
      const [, _usdtPrice] = await pancakeRouter.methods
        .getAmountsOut(new BigNumber(1e18).toString(), [
          constants.tokenAddress,
          constants.usdtAddress,
        ])
        .call();

      setBnbPrice(new BigNumber(_bnbPrice));
      setUsdtPrice(new BigNumber(_usdtPrice));
      const results = await Promise.all([
        bnbLpToken.methods.balanceOf(taddress).call(),
        bnbFarm.methods.deposited(index, address).call(),
        bnbFarm.methods.pending(index, address).call(),
      ]);
      const [_lpBalance, _depositedAmount, _pending] = results;
      // setLpBalance(new BigNumber(_lpBalance));
      setDepositedAmount(new BigNumber(_depositedAmount));
      setPending(new BigNumber(_pending));
    }
  }, [address, bnbFarm, pancakeRouter]);

  useEffect(() => {
    if (taddress && isWeb3Enabled && web3.eth && address) {
      lpContract = new web3.eth.Contract(erc20abi, taddress);
      lpContract.methods
        .balanceOf(address)
        .call()
        .then((r) => setLpBalance(r))
        .catch((e) => console.log("Error in lp address ->", e));
    }
  }, [taddress, web3.eth, address, quoteTokenAddress]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    dencrypt(values[0]);
  }, [dencrypt]);

  const handleFarmClose = useCallback(() => {
    setFarmModal(false);
    setFarmModal2(false);
  }, []);

  const [open, setOpen] = useState(false);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const displayDepositValue = useMemo(() => {
    return new BigNumber(lpBalance).multipliedBy(1e-18).toFixed(4);
  }, [lpBalance]);

  const displayPendingValue = useMemo(() => {
    return new BigNumber(pending).multipliedBy(1e-18).toFixed(4);
  }, [pending]);

  const handleClaimRewards = useCallback(async () => {
    if (!pending.isGreaterThan(0)) {
      SweetAlertNoButtons("info", "You Don't have Any reward to claim.");
      return;
    }
    try {
      setClaiming(true);

      const success = await bnbFarm.methods.withdraw(index, "0").send({
        from: address,
      });

      if (success) {
        SweetAlertNoButtons("Success", "You have claimed your rewards.");
      }

      fetchData();
    } catch (error) {
      console.error(error);
      SweetAlertNoButtons("Error", error.message);
    } finally {
      setClaiming(false);
    }
  }, [address, bnbFarm, fetchData, pending]);

  const depositValue = useMemo(() => {
    return new BigNumber(lpBalance);
  }, [lpBalance]);

  const handleDeposit = useCallback(async () => {
    if (!depositValue.isGreaterThan(0)) {
      SweetAlertNoButtons("Error", "You Don't have LP token in your wallet.");
      return;
    }

    try {
      setLoading(true);
      setDepositing(true);
      lpContract = new web3.eth.Contract(erc20abi, taddress);
      lpContract.methods
        .balanceOf(address)
        .call()
        .then((r) => setLpBalance(r))
        .catch((e) => console.log("Error in lp address ->", e));

      const allowance = await lpContract.methods
        .allowance(address, constants.farmAddress)
        .call();

      if (!new BigNumber(allowance).isGreaterThanOrEqualTo(depositValue)) {
        await lpContract.methods
          .approve(constants.farmAddress, "9".repeat(64))
          .send({
            from: address,
          });

        SweetAlertNoButtons(
          "Success",
          "Approved your LP token spending. Now proceed with deposit."
        );
      }

      const success = await bnbFarm.methods
        .deposit(index, depositValue.toFixed(0))
        .send({
          from: address,
        });

      if (success) {
        SweetAlertNoButtons(
          "Success",
          "You have deposited your LP tokens and now is part of the farm."
        );
      }

      fetchData();
    } catch (error) {
      console.error(error);
      SweetAlertNoButtons("Error", error.message);
    } finally {
      setDepositing(false);
    }
  }, [
    address,
    bnbLpToken,
    lpContract,
    bnbFarm,
    displayDepositValue,
    lpBalance,
    fetchData,
  ]);

  const BLOCKS_PER_YEAR = 2302420;
  const [frenPrice, setFrenPrice] = useState(0);
  const frenRewardsPerYear = useMemo(() => {
    return new BigNumber(BLOCKS_PER_YEAR);
  }, []);

  useEffect(() => {
    (async () => {
      if (pancakeRouter) {
        const amounts = await pancakeRouter.methods
          .getAmountsOut(new BigNumber(1e18).toString(), [
            constants.usdtAddress,
            constants.ethAddress,
          ])
          .call();

        const usdtPrice = amounts[1];
        setFrenPrice(new BigNumber(usdtPrice).multipliedBy(1e-18).toNumber());
      }
    })();
  }, [pancakeRouter]);

  const oneyearapy = useMemo(() => {
    return new BigNumber(frenRewardsPerYear).multipliedBy(frenPrice);
  }, [frenRewardsPerYear, frenPrice]);

  const apy = useMemo(() => {
    return new BigNumber(oneyearapy).multipliedBy(4);
  }, [oneyearapy]);

  const displayApr = useMemo(() => {
    return new BigNumber(apy).multipliedBy(1e-18).toFixed(4);
  }, [apy]);

  return (
    <>
      <Farm
        open={farmModal}
        onClose={handleFarmClose}
        onUpdate={fetchData}
        index={index}
        lp={lpBalance}
      />
      <Farm2
        open={farmModal2}
        onClose={handleFarmClose}
        onUpdate={fetchData}
        index={index}
        lp={bnbFarmStaked}
      />

      {/* First Farm Card Start */}
      <div className="col-md-4 col-12 mb-4">
        <div className="farm_card">
          <div className="farm_pair_section">
            <img
              src={FarmLogo}
              className="farm_pair_img"
              width="50px"
              alt="ETH"
            />
            <div className="farm_pair_content">
              <h3>{name} </h3>
            </div>
          </div>
          <div className="farm_count_section">
            <div className="farm_count_left">
              <span>APR</span>
              {/* <span>Earn</span> */}
              <span>Total Staked</span>
              <span>Token Price</span>
            </div>
            <div className="farm_count_right">
              <span>
                <APR
                  index={index}
                  usdtPrice={usdtPrice}
                  taddress={taddress}
                  quoteTokenAddress={quoteTokenAddress}
                ></APR>{" "}
                %
              </span>
              {/* <span>BTNT</span> */}
              <span>
                {displayBnbFarmStaked} {farm ? "LP" : "BTNTV2"}
              </span>
              {index === 0 ? (
                <span>
                  {displayBnbPrice} {priceTag}
                </span>
              ) : (
                <span>
                  {displayUSDTPrice} {priceTag}
                </span>
              )}
            </div>
          </div>
          <div className="farm_harvest_section">
            <div className="farm_harvest_count">
              <h3>BTNTV2 Earned</h3>
              <span>{displayPendingValue}</span>
            </div>
            <div
              className="farm_harvest_btn"
              onClick={() => {
                handleClaimRewards();
              }}
            >
              <button>Claim BTNTV2</button>
            </div>
          </div>

          {address ? (
            <div>
              <div className="farm_unlock_wallet">
                <h3>{farm ? "LP Balance" : "BTNTV2 Balance"}</h3>
                <span>{displayDepositValue}</span>
                {/* <button type="button" className="unlock_btn">Unlock Wallet</button> */}
              </div>
              <div className="farm-dep-with">
                <button
                  type="button"
                  onClick={() => {
                    handleDeposit();
                  }}
                  className="deposit-btn"
                >
                  Stake
                </button>
                <button
                  type="button"
                  onClick={() => setFarmModal2(true)}
                  className="withdraw-btn"
                >
                  {" "}
                  Unstake
                </button>
              </div>
            </div>
          ) : (
            <div>
              {" "}
              <button
                type="button"
                className="unlock_btn"
                onClick={() => handleClickOpen()}
              >
                Unlock Wallet
              </button>
            </div>
          )}
        </div>
      </div>
      <Dialog open={open} handleClose={handleClose} />
    </>
  );
};

export default Card;
