// MAINNET

const constants = {
  tokenAddress: "0xCbd787129d6e36225D93E46fA08556cC7F3a2458", // BTNT -- uni
  farmAddress: "0xE2D2af20291cCB32E9A14fc2616D58970463d7D8", // FARM
  lpAddress: "0x33EC58DD2c0cD63ceb588191b240aE2430663039", // LP-DEFAULT
  routerAddress: "0x10ED43C718714eb63d5aA57B78B54704E256024E", // ROUTER
  ethAddress: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
  usdtAddress: "0x55d398326f99059fF775485246999027B3197955", //  WETL
  multiCall: "0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B",
  btntV1tov2MigrationAddress: "0xD0b956013D462234f23F185520fEb56C404929e1",
};
// export default constants;

// TESTNET
// const constants = {
//   tokenAddress: "0x7E6942fAE32F0aD9B3249824530F1939a3eF5A83",
//   farmAddress: "0xa25879341E85F94659BEb40619D8E071091AC0Fd",
//   lpAddress: "0xF8DD9d9854e157C66Fc9e7191F0ce2E2a849fdEE",
//   routerAddress: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
//   ethAddress: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
//   usdtAddress: "0xd09b8734dBe94C0FDd4B12E565E274A44a805D0b",
// };
export default constants;
