/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useRef, useState } from "react";
import "../../utils/style.css";
import Header from "../../components/Header/Header";
// Animate on scroll
import Functions from "../../components/Functions/Functions";
import AboveFooter from "../../components/AboveFooter/AboveFooter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import {WindowChain} from '../../react-app-env'


//Images

import copy from "../../assets/copy.svg";
import {
  faCheck,
  faGrinSquint,
  faGrinTongue,
  faKissWinkHeart,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import NumberFormat from "react-number-format";
import CountUp from "react-countup";

const Homenou = () => {
//   const [holders, setHolders] = useState(0);
//   const [tokenInfo, setTokenInfo] = useState();
//   const [totalSupply, setTotalSupply] = useState(0);
//   const [loading, setLoading] = useState(true);
//   const [fetchis, setFetchis] = useState(true);
//   const [MEALInfo, setMEALInfo] = useState({});

//   useEffect(() => {
//     fetch(
//       "https://api.bscscan.com/api?module=token&action=tokenholderlist&contractaddress=0x8bac6b4af65c8c1967a0fbc27cd37fd6059daa00&page=1&offset=10000&apikey=TTA81VFV38X6FN98ZRYKPSR8WNJTTAII5V"
//     )
//       .then((response) => response.json())
//       .then((data) => setHolders(data.result.length))
//       .then((data) => setFetchis(true));
//   }, []);

//   useEffect(() => {
//     fetch(
//       "https://api.bscscan.com/api?module=token&action=tokeninfo&contractaddress=0x8bac6b4af65c8c1967a0fbc27cd37fd6059daa00&apikey=TTA81VFV38X6FN98ZRYKPSR8WNJTTAII5V"
//     )
//       .then((response) => response.json())
//       .then((data) => setTokenInfo(data.result[0]));
//   }, []);

//   useEffect(() => {
//     if (!loading && tokenInfo) {
//       setTotalSupply(tokenInfo.totalSupply);
//     }
//   }, [loading, tokenInfo]);

//   useEffect(() => {
//     if (fetchis === true) {
//       setLoading(false);
//     }
//   }, [fetchis]);

//   async function fetchMEALData() {
//     await fetch(new Request("https://api.livecoinwatch.com/coins/single"), {
//       method: "POST",
//       headers: new Headers({
//         "content-type": "application/json",
//         "x-api-key": "14de80e1-d31c-4a84-b6f5-28c35520e11c",
//       }),
//       body: JSON.stringify({
//         currency: "USD",
//         code: "_MEAL",
//         meta: true,
//       }),
//     })
//       .then((response) => response.json())
//       .then((data) => setMEALInfo(data));
//   }

//   useEffect(() => {
//     fetchMEALData();
//   }, []);


// //   useEffect(() => {
// //     (async () => {
// //       if (isWeb3Enabled) {
// //         const [account] = await web3.eth.getAccounts();
// //         setAddress(account);
// //       }
// //     })();
// //   }, [isWeb3Enabled, web3.eth]);



// //   const [userDataId, setUserDataId] = useState('')
// //   const [copySuccess, setcopySuccess] = useState('')

// //   useEffect(() => {
// //     const userId = async () => {
// //       const provider =  await (window as WindowChain).ethereum.selectedAddress
// //       setUserDataId(provider.toString())
// //       console.log("asdfghjhkjhgfdsa",provider);
// //     }
// //     await userId()
// //   }, [setUserDataId])


// //   // const { login, logout } = useAuth()

// //   const domainLink1 = userDataId ? `https://mealswap.finance/farms?reff=${userDataId}` : `https://mealswap.finance/farms?ref=`
// //   const domainLink2 = `https://mealswap.finance/farms?reff=`


// //   // const textAreaRef = useRef(null);
// //  const copyToClipboard = (e) => {
// //    textAreaRef.current.select();
// //    document.execCommand('copy');
// //    e.target.focus();
// //    setcopySuccess('Copied!');
// //  }

//   return (
//     <>
//       <Header />

//       <div class="main_body text-center">
//         <div class="container">
//           <div class="header_img">
//             <h1>BitNautic</h1>
//             <p>BitNautic, the lightest exchange on BitNautic Smart Chain.</p>
//             {/* <img src={mealing} alt="Main logo" class="mealing_img hero_img" /> */}
//           </div>
//           <div class="row">
//             <div class="col-lg-6">
//               <div class="card card_setting">
//                 <div class="card-header meal_swap">
//                   <h1 class="m-0">Farms & Staking</h1>
//                 </div>
//                 <div class="card-body">
//                   {/* <div class="burger">
//                     <img src={burger} alt="Main logo" class="mealing_img hero_img" />
//                   </div> */}
//                   <div class="row">
//                     <div class="col-lg-8">
//                       <div class="row">
//                         <div class="col-lg-12">
//                           <div class="title_haverest">
//                             <h4>ETH to Harvest:</h4>
//                             <p>Locked</p>
//                           </div>
//                         </div>
//                         <div class="col-lg-12">
//                           <div class="title_haverest">
//                             <h4>ETH in Wallet:</h4>
//                             <p>Locked</p>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                     <div class="col-lg-12 card_btn">
//                       <button class="btn wallet_btn">Unlock Wallet</button>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div class="col-lg-6">
//               <div class="card  card_setting">
//                 <div class="card-header meal_swap">
//                   <h1 class="m-0">Our latest news</h1>
//                 </div>
//                 <div class="card-body card_ludo">
//                   {/* <div class="burger">
//                     <img src={ludo} alt="Main logo" class="mealing_img hero_img" />
//                   </div> */}

//                   {/* <div class="row">
//                     <div class="col-lg-8">
//                       <div class="row">
//                         <div class="col-lg-6">
//                           <div class="title_haverest">
//                             <h4>Meal to Collect:</h4>
//                             <p>Locked</p>
//                           </div>
//                         </div>
//                         <div class="col-lg-6">
//                           <div class="title_haverest">
//                             <h4>Total jackpot this round:</h4>
//                             <p>Coming Soon</p>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                     <div class="col-lg-8 card_btn">
//                       <button class="btn wallet_btn">Unlock Wallet</button>
//                     </div>
//                   </div>
//                  */}
//                 </div>
//               </div>
//             </div>

//           </div>
//         </div>
//       </div>
      
//       <div class="main_offer">
//         <div class="container">
//           <div class="row">
//             <div class="col-lg-6 col-sm-12">
//               <div class="card card_setting">
//                 <div class="card-body card_content">
//                   <h4>ETH Stats</h4>

//                   <div class="d-flex market justify-content-between">
//                     <p>Total ETH Supply</p>
//                     <div class="meal_state_id market_value">0</div>
//                   </div>
//                   <div class="d-flex market justify-content-between">
//                     <p>Total ETH Burned</p>
//                     <div class="meal_state_id market_value" >0.000</div>
//                   </div>
//                   <div class="d-flex market justify-content-between">
//                     <p>New ETH/block</p>
//                     <div class="meal_state_id market_value">0</div>
//                   </div>                  
//                 </div>
//               </div>
//             </div>
//             <div class="col-lg-6 col-sm-12">
//               <div class="card card_setting">
//                 <div class="card-body card_content">
//                   <h3>Total Value Locked (TVL)</h3>
//                   <h2>$6,508,425,381</h2>
//                   <h5>Across all Mealing and Flavour Pools</h5>
//                 </div>
//               </div>
//             </div>

//           </div>
//         </div>
//       </div>


//       {/* <div class="main_offer">
//         <div class="container">
//           <div class="row">
//             <div class="col-lg-6 col-sm-12">
//               <div class="card card_setting">
//                 <div class="card-body card_content">
//                   <h4>Referral</h4>
//                   <div class="xyz">
//                     <img src={copy} class="copy_img" alt="copy" />
//                     <input class="refferal_link" scale="md" value="https://mealswap.finance/farms?reff=" />
//                     <button class="btn wallet_btn">Copy Link</button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div class="col-lg-6 col-sm-12">
//               <div class="card card_setting">
//                 <div class="card-body card_content">
//                   <h4>Trade</h4>
//                   <div>
//                     <p class="referral_para"> Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta sed, sint ipsa eius vel in quia fuga dolores repellat commodi! </p>
//                     <div class="row">
//                       <div class="col-lg-6">
//                       <button class="btn wallet_btn">Exchange</button>
//                       </div>
//                       <div class="col-lg-6">
//                       <button class="btn wallet_btn">Liquidity</button>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div> */}


//       {/* <div class="main_offer">
//         <div class="container">
//           <div class="row">
//             <div class="col-lg-4 col-sm-6">
//               <div class="card card_setting">
//                 <div class="card-body card_content">
//                   <h3>Earn up to </h3>
//                   <h2>0%</h2>
//                   <div class="d-flex justify-content-between">
//                     <h3> APR in Mealing</h3>
//                     <svg viewBox="0 0 24 24" color="primary" width="30px" xmlns="http://www.w3.org/2000/svg" class="sc-bdnxRM ccaPUv"><path d="M5 13H16.17L11.29 17.88C10.9 18.27 10.9 18.91 11.29 19.3C11.68 19.69 12.31 19.69 12.7 19.3L19.29 12.71C19.68 12.32 19.68 11.69 19.29 11.3L12.71 4.7C12.32 4.31 11.69 4.31 11.3 4.7C10.91 5.09 10.91 5.72 11.3 6.11L16.17 11H5C4.45 11 4 11.45 4 12C4 12.55 4.45 13 5 13Z"></path></svg>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div class="col-lg-4 col-sm-6">
//               <div class="card card-bg-change card_setting">
//                 <div class="card-body card_content">
//                   <h3>Earn in </h3>
//                   <h2 class="text-white">Flavour, MEAL</h2>
//                   <div class="d-flex justify-content-between">
//                     <h3> Pools</h3>
//                     <svg viewBox="0 0 24 24" color="primary" width="30px" xmlns="http://www.w3.org/2000/svg" class="sc-bdnxRM ccaPUv"><path d="M5 13H16.17L11.29 17.88C10.9 18.27 10.9 18.91 11.29 19.3C11.68 19.69 12.31 19.69 12.7 19.3L19.29 12.71C19.68 12.32 19.68 11.69 19.29 11.3L12.71 4.7C12.32 4.31 11.69 4.31 11.3 4.7C10.91 5.09 10.91 5.72 11.3 6.11L16.17 11H5C4.45 11 4 11.45 4 12C4 12.55 4.45 13 5 13Z"></path></svg>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div class="col-lg-4 col-sm-6">
//               <div class="card card_setting">
//                 <div class="card-body card_content">
//                   <h3>Doughnut</h3>
//                   <h2>Coming Soon... </h2>
//                   <div class="d-flex justify-content-between">
//                   <svg viewBox="0 0 24 24" color="primary" width="30px" xmlns="http://www.w3.org/2000/svg" class="sc-bdnxRM ccaPUv"><path d="M5 13H16.17L11.29 17.88C10.9 18.27 10.9 18.91 11.29 19.3C11.68 19.69 12.31 19.69 12.7 19.3L19.29 12.71C19.68 12.32 19.68 11.69 19.29 11.3L12.71 4.7C12.32 4.31 11.69 4.31 11.3 4.7C10.91 5.09 10.91 5.72 11.3 6.11L16.17 11H5C4.45 11 4 11.45 4 12C4 12.55 4.45 13 5 13Z"></path></svg>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div> */}

      
//     </>
//   );
};

export default Homenou;
