import React, { useState, useCallback } from "react";
import styled from "styled-components";
import Navitems from "../Navitems/Navitems";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "../../Dialog/Dialog";
import { useWallet } from "../../../store/wallet";
import { useContracts } from "../../../store/contracts";
import BigNumber from "bignumber.js";
import { useMemo } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
// import { BTNTLOGO } from "../../../../public/bitnautic-logo.png"

// Images
import BTNTLOGO from "../../../assets/bitnautic-logo.png"

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paper": {
      width: "500px",
      minHeight: "450px",
    },
  },
}));

const MiddleAlign = styled.div`
  display: flex;
  color: #fff;
  align-items: center;
  width: 120px;
  height: 40px;
  background: #238fff;
  border-radius: 40px;
  text-align:center;
`;

const Navbarnou = () => {
  const classes = useStyles();

  const { isWeb3Enabled, address } = useWallet();
  const { btntToken } = useContracts();

  const [open, setOpen] = useState(false);
  const [balance, setBalance] = useState(new BigNumber(0));

  useEffect(() => {
    (async () => {
      if (btntToken && btntToken.methods && address) {
        const _balance = await btntToken.methods.balanceOf(address).call();
        setBalance(new BigNumber(_balance));
      }
    })();
  }, [address, btntToken]);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const displayBalance = useMemo(() => {
    return balance.multipliedBy(1e-18).toNumber().toLocaleString();
  }, [balance]);

  return (
    <>
      <div class="custom-nav text-center sticky-top">
        <div class="container-fluid">
          <div class="d-flex align-items-center custom-navbar justify-content-lg-between flex-column flex-lg-row">
          <div className="d-flex flex-center align-items-center">
            <Link to="/">
              <img
                alt="BitNautic Logo"
                src={BTNTLOGO}
                className="w-150px"
              />
            </Link>
          </div>
            <div class="d-flex flex-column flex-lg-row text-center">
              <Navitems />
            </div>
            {isWeb3Enabled ? (
              <MiddleAlign>
                {/* <img
                  alt="BitNautic Logo"
                  src={MainLogo}
                  className="w-30px"
                /> */}
                {address ? address.substring(0,5) + '...' + address.substring(address.length-5) : 'Connect'}
              </MiddleAlign>
            ) : (
              <span
                className="con-b position-relative"
                onClick={() => handleClickOpen()}
              >
                Connect
              </span>
            )}
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        className={classes.dialog}
        handleClose={handleClose}
      />
    </>
  );
};

export default Navbarnou;
