import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
const Li = styled.li`
  display: flex;
  height: 100%;
  padding: 0;

  &.active {
    border-bottom: 2px solid var(--color-background-15);
  }
`;
const StyledNavLink = styled(Link)`
  display: flex;
  text-transform: inherit;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  padding: ${(props) => (props.mobile ? "0px 10px" : "0px 10.5px")};
  margin: 0 5px !important;
  transition: all 0.2s;
  line-height: 1.5 !important;
  cursor: pointer;
  background-color: ${(props) => (props.signup ? "#000" : "")};
  color: ${(props) =>
    props.signup ? "var(--color-background-13)" : "var(--color-background-13)"};
  border-bottom: 4px solid transparent;
  &:hover {
    text-transform: inherit !important;
    margin: 0 5px !important;
    /* color: var(--color-background-15) !important; */
    border-bottom: 4px solid var(--color-background-13);
  }

  &.active {
    text-transform: inherit !important;
    margin: 0 5px !important;
    /* color: var(--color-background-15) !important; */
    border-bottom: 4px solid var(--color-background-13);
  }
`;
const NavItem = ({
  link,
  children,
  fromnav,
  mobile,
  clicked,
  auth,
  signup,
  isActive,
  to,
  too,
  newState
}) => {
  return (
    <div class={`flex-fill ${newState ? "new-state-nav position-relative" : ""}`}>
      <Link
        to={to} 
        primary={"true"} 
        smooth={"true"}
        duration={500}
        // spy={true}
        exact="true"
        offset={-80}
      >
        {children}
      </Link>
    </div>
  );
};

export default NavItem;
