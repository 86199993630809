/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import Navbar from "../../components/Navigation/Navbar/Navbarnou";
import "./vaults.css";
import Card from "./Card";
import farms from "../../jsons/farms.json";
import { Alert } from "@mui/material";

const Vaults = () => {
  return (
    <>
      <Navbar />
      <div class="main_offer">
        <div className="container-fluid">
          <div class="header_img">
            <h1>BitNauticV2 Reward Pools</h1>
            <p>Stake BTNTv2 Pancake LP tokens to earn BTNTV2.</p>
            <Alert severity="warning">
              For any BTNT token Support contact us on our official chat{" "}
              <a
                href="https://t.me/bitnautic_group"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                https://t.me/bitnautic_group{" "}
              </a>
              .
            </Alert>
          </div>
          <div className="row">
            {farms.map((i, index) => {
              return (
                <Card
                  index={i.index}
                  name={i.pair}
                  farm={true}
                  taddress={i.address}
                  priceTag={i.priceTag}
                  quoteTokenAddress={i.quoteTokenAddress}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Vaults;
